//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import i18n from '@/vueI18n';
// import { getLanguageCode } from '@/i18n';

export default {
  name: 'app-messages-show-page',
  props: ['openImg','otherUserId','user','isClosed'],
  data: () => ({
    standard: 0,
    audios: [],
  }),
  computed: {
    ...mapGetters({
      channelUsers: 'channelUsers',
      //channelData: 'channelData',
      currentUser: 'auth/currentUser',
      userRecord: 'iam/view/record',
      mediaRecorder: 'mediaRecorder',
      currentLanguage:'layout/currentLanguage',
      messages:'complaint/messagesNEW',
      complaintsArr:'complaint/complaintsArr',
    }),
    language(){
      return i18n.locale
    },
    selectedMessages(){
      // const arr = this.messages.filter(I=> I.complaintId === this.otherUserId)
      // Object.keys(this.messages[this.otherUserId]).forEach(key => {
      //   arr.push(this.messages[this.otherUserId][key])
      // })
      const arr= this.messages
      return arr.sort(function (a, b) {
        var keyA = new Date(a.sentAt.seconds),
          keyB = new Date(b.sentAt.seconds);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
  },
  watch: {
    selectedMessages:{
      // immediate: true,
      handler() {
        // this.doFetchMessageUser();
        this.scrollBottom()
      },
      deep: true,
    },

    otherUserId: {
      immediate: true,
      handler() {
        this.scrollBottom();
        // this.doFindById(newVal);
        // this.getChannelId({
        //   senderId: this.currentUser.id,
        //   userId: newVal,
        // }).then((data) => {
        //   this.setChannelId(data);
        //   // this.channelId = data // setter
        // });
        // this.setIsRead({
        //   senderId: this.currentUser.id,
        //   otherUserId: this.otherUserId,
        // });
      },
    },
  },
  created() {
    // this.getChannelId({
    //   senderId: this.currentUser.id,
    //   userId: this.otherUserId,
    // }).then((data) => {
    //   this.setChannelId(data);
    //   // this.channelId = data // setter
    // });
      this.scrollBottom();
  },
  methods: {
    ...mapActions({
      retrySendMessage: 'retrySendMessage',
      setChannelId: 'setChannelId',
      setIsRead: 'setIsRead',
      close:'close',
      doFetchMessageUser: 'complaint/doFetchMessageUser'
      // doFindById: 'iam/view/doFindById',
      // getChannelId: 'getChannelId',
    }),
    doClose(){
      this.close(this.otherUserId)
    },
    getMessageState(message) {
      if (message.isRead) {
        return '✓✓';
      }
      if (message.isLoading) {
        return '';
      }
      return '✓';
    },
    getStamp(date) {
      moment.locale(this.language);
      return moment(date).fromNow();
    },
    getAvatar() {
      var complaint = this.complaintsArr.filter( (itm) => {
        return itm.id==this.otherUserId
      });
      var user;
      if(complaint.length>0){
        user = complaint[0].createdBy;

      }
      if(user && user.avatars && user.avatars.length && user.avatars[0].publicUrl) return user.avatars[0].publicUrl

      
      if (
        user &&
        user.gender &&
        user.gender.toLowerCase() == 'female'
      ) {
        return 'images/default/avatar.svg';
      }
      return 'images/default/avatar.svg';
    },
    getMessageAvatar(user) {
      if(user==this.user.authenticationUid){
        if(this.user && this.user.avatars && this.user.avatars.length && this.user.avatars[0].publicUrl) return this.user.avatars[0].publicUrl
      
      if (
        this.user &&
        this.user.gender &&
        this.user.gender.toLowerCase() == 'female'
      ) {
        return 'images/default/avatar.svg';
      }
      return 'images/default/avatar.svg';
      }
      else{
        if(this.currentUser && this.currentUser.avatars && this.currentUser.avatars.length && this.currentUser.avatars[0].publicUrl) return this.currentUser.avatars[0].publicUrl
      
      if (
        this.currentUser &&
        this.currentUser.gender &&
        this.currentUser.gender.toLowerCase() == 'female'
      ) {
        return 'images/default/avatar.svg';
      }
      return 'images/default/avatar.svg';
      }
      
    },
    scrollBottom() {
      setTimeout(() => {
        var container = document.querySelector(
          '#messages-container',
        );
        if (container) {
          container.scrollTop = container.scrollHeight;
          this.textMessage = null;
        }
      }, 10);
    },
    replaceTimestampToDate(timestamp) {
      return timestamp.toDate()
      // return moment().locale(this.language).format("dddd");
    },
    presenterDateTime(timestamp) {
      const dateTime = this.replaceTimestampToDate(timestamp)
      const date = moment(dateTime).locale(this.language).format('MMM DD')
      const time = moment(dateTime).locale(this.language).format('hh:mm A')
      return this.language == 'ar' ? `الساعة ${time}  ${date} ` : `${date} At ${time}`
      // return moment(dateTime).locale(this.language).format('hh:mm a')
    },
  },
};
