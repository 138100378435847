//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'

export default {
  name: 'app-messages-list-table',
  props: ['openMessage','closeMessage'],
  data() {
    return {
      textMessage: '',
      tab: 'pending',
      selectedData: {},
      visibleDialog: false,
      Timer: true,
      isInit: true,
      confirm:false,
      selectedId:undefined,
      selectedValue:undefined,
    };
  },
  created() {
    this.doFetch({ filter : { userType : 'client' } })
    this.setComplaintListener('pending')
    setTimeout(() => {
      this.isInit = false,
      this.setTimerFalse()
    }, 0);
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
      currentUser: 'auth/currentUser',
      currentLanguage:'layout/currentLanguage',
      complaintsList:'complaint/complaintsList',
      complaintsArr:'complaint/complaintsArr',
      usersList:'iam/list/rows',
      complaintUser:'iam/list/complaintUser',
    }),
    Contacts() {
      let arr = []
      arr = this.complaintsArr.filter(I => I.id.toLowerCase().includes(this.textMessage.toLowerCase()) || 
      this.getStamp(I.createdAt.seconds).includes(this.textMessage.toLowerCase()) )
      arr = arr.filter(I => I.status === this.tab)
      return arr.sort(function (a, b) {
        var keyA = new Date(a.createdAt.seconds),
          keyB = new Date(b.createdAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
     
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    complaintsArr(){
      this.doFetchComplaintUser();
    }
  },
  methods: {
    ...mapActions({ setComplaintListener:'complaint/setComplaintListener',
    close:'complaint/close',
    doFetch:'iam/list/doFetch',
    doFetchComplaintUser:'complaint/doFetchComplaintUser'
    }),
    doChangeLoc(id){
      let value = !this.selectedValue
      this.close({id,value})
      this.confirm = false
      setTimeout(() => {
        let messageItem = this.complaintsArr.find(I => I.id === id)
        this.openMessage(messageItem.id,this.getUser(messageItem.createdBy),messageItem.isClosed)
      }, 100);
    },
    doLock(id,value){
      if(value === true){
        this.confirm = true
        this.selectedId = id
        this.selectedValue = value
      }
      else{
        value = !value
        this.close({id,value})
        setTimeout(() => {
          let messageItem = this.complaintsArr.find(I => I.id === id)
          this.openMessage(messageItem.id,this.getUser(messageItem.createdBy),messageItem.isClosed)
      }, 100);
      }
    },
    setTimerFalse(){
      this.Timer = false
    },
    getUser(id) {
      const user = this.usersList.find(I=>I.id === id || I.authenticationUid === id)
      if (user) {
        return user;
      }
      return {};
    },
     getAvatar(user) {
      // const user = this.usersList.find(I=>I.id === id || I.authenticationUid === id)
      if(user &&  user.avatars && user.avatars.length && user.avatars[0].publicUrl) return user.avatars[0].publicUrl
      if (user &&
        user.gender &&
        user.gender.toLowerCase() == 'female'
      ) {
        return 'images/default/avatar.svg';
      }
      return 'images/default/avatar.svg';
    },
    openChat(data) {
      this.selectedData = data;
      this.visibleDialog = true;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    getStamp(date) {
      moment.lang(this.language);
      return moment(date, 'X').format('DD/MM/YYYY hh:mm A');
    },
  },
};
