//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Message from '@/modules/complaint/components/complaint.vue';
// import MessagesNew from '@/modules/complaint/components/complaint-new.vue';
import MessagesListTable from '@/modules/complaint/components/complaint-list-table.vue';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'
export default {
  props: ['senderId'],
  name: 'app-messages-list',
  data() {
    return {
      newMessagesDialog: false,
      MessagesDialog: false,
      otherUserId: null,
      isNew: false,
      isClosed:false,
    };
  },
  created() {
    if (this.$route.query.senderId) {
      this.openMessage(this.$route.query.senderId);
    }
  },
  components: {
    [Message.name]: Message,
    [MessagesListTable.name]: MessagesListTable,
  },
  methods: {
    ...mapActions({
      setOtherUserID: 'setOtherUserID',
      initNewChat: 'initNewChat',
      seenChat: 'seenChat',
      setMessagesListener:'complaint/setMessagesListener'
      
    }),
    ChangeState() {
      if (this.isNew) {
        this.isNew = false;
      }
    },
    openMessage(otherUserId,user,isClosed, isNew = false) {
      this.otherUserId = otherUserId;
      this.user = user;
      this.isClosed = isClosed
      this.newMessagesDialog = false;
      this.isNew = isNew;
      if (window.innerWidth <= 599) {
        this.MessagesDialog = true;
      }
      this.setMessagesListener(otherUserId)
    },

    closeMessage(){
      this.otherUserId=null
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
  },
  computed: {
    ...mapGetters({
      // channelData: 'complaint/channelData',
      currentUser: 'auth/currentUser',
      openComplaint: 'complaint/openComplaint',
    }),
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.complaint') 
    },
  },
};
